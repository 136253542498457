<template>
    <section class="sidebar" :class="{'show' : showSidebar}">
        <!--<div class="sidebar-logo">
            <router-link :to="{name: 'dashboard'}">
                <svg class="sidebar-site-logo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 1200 800" style="enable-background:new 0 0 1200 800;" xml:space="preserve" fill="#fff">
                    <g>
							<circle class="st0" cx="953.6" cy="697.4" r="40.1"/>
                        <path class="st0" d="M825.7,661.3c-17.9,0-32.4,14.5-32.4,32.4v0c0,17.9,14.5,32.4,32.4,32.4c17.9,0,32.4-14.5,32.4-32.4v0
								C858.1,675.8,843.6,661.3,825.7,661.3z"/>
                        <path class="st0" d="M1039.3,752.1c-8.4,0-15.2,6.8-15.2,15.2v0c0,8.4,6.8,15.2,15.2,15.2c8.4,0,15.2-6.8,15.2-15.2v0
								C1054.5,758.9,1047.7,752.1,1039.3,752.1z"/>
                        <circle class="st0" cx="891.3" cy="778.9" r="21.1"/>
                        <g>
								<path class="st0" d="M512.5,215.4c4,1,8,1.4,12,1.3c0.3,0,0.6,0,0.9,0c23.6,0,42.8-19.2,42.8-42.8s-19.2-42.8-42.8-42.8
									c-23.6,0-42.8,19.2-42.8,42.8c0,16.7,9.5,31.1,23.4,38.1C508.1,213.4,510.2,214.5,512.5,215.4z"/>
                            <path class="st0" d="M802,496.6c30.9-49.5,48.8-107.9,48.8-170.5C850.9,170.1,739.4,39.5,592,9.9c-21.8-4.7-44.4-7.2-67.6-7.2
									c-178.5,0-321.3,145.2-321.3,323.7c0,151,101.5,278.2,241.6,313.7c-23.1-10.8-176.7-116.4-175-323c1.1-133.2,112.9-241,246-245.4
									c144.6-4.8,263.7,111.6,263.4,255.1c-0.2,106.5-64.4,196.8-156.6,234.9c-19.6,6.6-87.5,22-143.5-54.5
									c-37.3-56.3-32.5-116.2-31-132.5c3.3-34.1,28.6-58.5,60.8-58.5c2,0,4,0.1,6,0.3c37.2,3.2,61,28.6,59.4,63.2
									c-1.4,28.8-9.1,59.4-21.7,86.3l-1.4,2.9c-1.9,4-4.2,9-5.9,13c-2,4.3-2.4,9.2-1.1,13.8c2.7,10.3,12.9,16.8,23.4,15l0.3-0.1
									c5.6-1,10.5-4.2,13.7-8.8c27.8-41,48.6-84.3,48.9-136.2c0.2-31.1-11-59.6-31.4-80.1c-21.3-21.5-51.5-33.3-85-33.3
									c-2.3,0-4.7,0.1-7,0.2c-16.8,0.8-32.5,4.4-46.8,10.4l0,0c0,0-71.1,23-76.6,105.1c-5.8,45.6-1.7,123,66.4,197.4
									c7.8,9.1,16.6,17.8,26.3,25.7c0,0,0.1,0.1,0.1,0.1l0,0c10.7,8.7,22.6,16.6,35.8,23.4c6.1,3.6,12.4,6.7,18.9,9.4
									c0.3,0.2,0.6,0.4,0.9,0.6c0,0,0,0,0,0l-0.1-0.2c12,4.9,24.6,8.3,37.4,10.2c0.1,0,0.1,0,0.2,0l0,0c27.8,3.9,56.8,0.4,83.9-11.4
									c21.3-9.3,41.3-20.8,59.9-34.3l52.5,49c14-12.8,32.5-20.7,53-20.7c32,0,59.6,19.2,71.7,46.8c6.6-17.8,21.4-31.6,39.8-36.8
									L802,496.6z"/>
							</g>
						</g>
					</svg>
            </router-link>
        </div>-->
        <div class="sidebar__content sidebar--primary">
            <div class="sidebar-top">
                <router-link :to="{name: 'dashboard'}">
                    <img src="/svg/logo-white.svg" class="site-logo" alt="easy.jobs">
                    <img src="/svg/logo-icon-white.svg" class="site-logo-icon" alt="easy.jobs">
                </router-link>
            </div>
            <nav class="sidebar-middle">
                <ul class="sidebar__nav">
                    <li v-for="(menuItem, index) in sidebarItems" class="sidebar__nav__item" :class="{'sidebar__nav__item--has-dropdown' : menuItem.hasChild}">
                        <a v-if="menuItem.hasChild" href="javascript:void(0)" @click="menuItem.isOpen = !menuItem.isOpen" class="sidebar__nav__link" :class="{'active' : menuItem.isActive}">
                            <div class="sidebar__nav__link__icon">
                                <i :class="menuItem.icon"></i>
                            </div>
                            <div class="sidebar__nav__link__text">{{ $t(menuItem.title) }}</div>
                        </a>
                        <router-link v-else :to="{name: menuItem.route}" class="sidebar__nav__link" :class="{'active' : menuItem.isActive}">
                            <div class="sidebar__nav__link__icon">
                                <i :class="menuItem.icon"></i>
                            </div>
                            <div class="sidebar__nav__link__text text-capitalize">{{ $t(menuItem.title) }}</div>
                        </router-link>
                        <ul id="JobInfo" class="sidebar__nav__dropdown-menu collapse show" v-if="menuItem.hasChild && menuItem.isOpen" v-on-click-away="hideThisMenu">
                            <li class="sidebar__nav__dropdown-menu__item" v-for="childMenu in menuItem.child">
                                <router-link
                                        :to="{name:childMenu.route}"
                                        class="sidebar__nav__dropdown-menu__link"
                                        active-class="active"
                                        exact
                                ><span @click="hideMenus">{{ $t(childMenu.title) }}</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="sidebar__nav__item sidebar__nav__item__logout mt-auto">
                        <router-link :to="{name: 'logout'}" class="sidebar__nav__link">
                            <div class="sidebar__nav__link__icon">
                                <i class="eicon e-logout"></i>
                            </div>
                            <div class="sidebar__nav__link__text">{{ $t("Logout") }}</div>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </section>
</template>
<script>
import menu from "../../config/menu";
import {mapState} from 'vuex';
import {SWITCHED_TO_ANOTHER_ACCOUNT} from '../../constants/events';
import {EventBus} from '../../extra/event-bus';
import {getPermission, hasRoutePermission} from '../../config/permission';

export default {
    data() {
        return {
            sidebarItems: [],
        }
    },

    computed: {
        ...mapState(['company', 'showSidebar']),
    },
    methods: {
        hideMenus(){
            if(this.showSidebar) {
                this.sidebarItems = this.sidebarItems.filter(i => {
                    if(i.isOpen) {
                        i.isOpen =false;
                    }
                    return i;
                });
            }
        },
        hideThisMenu(){
            if(this.showSidebar) {
                this.sidebarItems = this.sidebarItems.filter(i => {
                    if (i.isOpen) {
                        i.isOpen = false;
                    }
                    return i;
                });
            }
        },
        loadSidebarMenu() {
            let mode = 'company';

            if (this.company === null) {
                mode = 'candidate';
            }

            this.sidebarItems = menu.dashboardSideBar[mode].filter((menu) => {
                menu.isActive = false;
                if (this.$route.name === menu.route) {
                    menu.isActive = true;
                } else if (menu.subRoutes && menu.subRoutes.indexOf(this.$route.name) > -1) {
                    menu.isActive = true;
                }

                if (mode === 'company') {
                    if (menu.child) {
                        menu.child = menu.child.filter((childMenu) => {
                            if (!hasRoutePermission(childMenu.route, getPermission())) {
                                return false;
                            }

                            if (!hasRoutePermission(menu.route, getPermission())) {
                                menu.route = childMenu.route;
                            }
                            return childMenu;
                        });
                    }

                    if(menu.route === 'company.setting.basic' && !hasRoutePermission(menu.route, getPermission()) && menu.subRoutes) {
                        menu.subRoutes.find((subRoute) => {
                            if (hasRoutePermission(subRoute, getPermission())) {
                                menu.route = subRoute;
                                return true;
                            }
                        });
                    }
                    return hasRoutePermission(menu.route, getPermission());
                }
                return menu;
            });
        }
    },

    beforeMount() {
        this.loadSidebarMenu();
    },

    mounted() {
        EventBus.$on(SWITCHED_TO_ANOTHER_ACCOUNT, (p) => {
            this.loadSidebarMenu();
        })
    },

    beforeDestroy() {
        EventBus.$off(SWITCHED_TO_ANOTHER_ACCOUNT);
    }
}
</script>
<style scoped>
    .sidebar .sidebar__content .sidebar__nav {
        list-style: none;
    }
</style>