export default {
    dashboardSideBar : {
        company : [
            {
                title : "Dashboard",
                icon : "eicon e-home",
                route : "dashboard"
            },
            {
                title: "Jobs",
                icon: "eicon e-briefcase",
                route: "jobs.published",
                subRoutes : [
                    "jobs.published",
                    "jobs.draft",
                    "jobs.archived",
                    "job.create",
                    'job.edit',
                    'job.screening',
                    'job.quiz',
                    'job.candidates',
                    'job.pipeline'
                ]
            },
            {
                title : "Candidates",
                icon : "eicon e-users",
                route : "company.candidates",
                subRoutes : [
                    "applicant.details"
                ]
            },
            {
                title : "Messages",
                icon : "eicon e-chat",
                route : "conversations"
            },
            {
                title : "Settings",
                icon : "eicon e-setting",
                route : "company.setting.basic",
                subRoutes : [
                    "company.setting.basic",
                    "company.setting.photo",
                    "company.setting.templates",
                    "company.setting.user",
                    "company.setting.user.add",
                    "company.setting.user.update",
                    "company.setting.app-key",
                    "company.setting.custom-domain",
                    "company.setting.pipeline",
                    "company.setting.email",
                    "company.setting.email.smtp",
                    "company.setting.user-app",
                    "company.setting.integration",
                    "company.setting.ai-setup",
                    "company.setting.verification",
                    "company.setting.apply-setting",
                    "company.setting.notifications",
                    "company.setting.activity-log",
                    "company.setting.category-skill"
                ]
            },

            {
                title : "Evaluation",
                icon : "eicon e-graduation",
                route : "company.question.group",
                hasChild: true,
                isOpen: false,
                subRoutes : [
                    "company.question.group",
                    "company.question.group.create",
                    "company.question.group.edit",
                    "company.assessments",
                    "company.assessments.create",
                    "company.assessments.edit"
                ],
                child: [
                    {
                        title : "Question Set",
                        icon : "eicon e-file",
                        route : "company.question.group",
                        // subRoutes : [
                        //     "company.question.group",
                        //     "company.question.group.create",
                        //     "company.question.group.edit"
                        // ]
                    },
                    {
                        title : "Assessment",
                        icon : "eicon e-graduation",
                        route : "company.assessments",
                        // subRoutes : [
                        //     "company.assessments",
                        //     "company.assessments.create",
                        //     "company.assessments.edit"
                        // ]
                    }
                ]
            },
            //
            //
            // {
            //     title : "Question Set",
            //     icon : "eicon e-file",
            //     route : "company.question.group",
            //     subRoutes : [
            //         "company.question.group",
            //         "company.question.group.create",
            //         "company.question.group.edit"
            //     ]
            // },
            // {
            //     title : "Assessment",
            //     icon : "eicon e-graduation",
            //     route : "company.question.group",
            //     subRoutes : [
            //         "company.question.group",
            //         "company.question.group.create",
            //         "company.question.group.edit"
            //     ]
            // }

        ],

        candidate : [
            {
                title : "Dashboard",
                icon : "eicon e-home",
                route : "dashboard"
            },
            {
                title : "Profile",
                icon : "eicon e-user",
                route : "candidate.basic-info",
                subRoutes : [
                    "candidate.basic-info",
                    "candidate.qualifications",
                    "candidate.employments"
                ]
            },
            {
                title : "Resume",
                icon : "eicon e-cv",
                route : "candidate.resume"
            },
            {
                title : "Applied Jobs",
                icon : "eicon e-briefcase",
                route : "candidate.applied-jobs"
            },
            {
                title : "Unfinished Jobs",
                icon : "eicon e-cv",
                route : "candidate.unfinished-jobs"
            },
            {
                title : "Messages",
                icon : "eicon e-chat",
                route : "applicantConversations",
                subRoutes: [
                    "candidate.message-details"
                ]
            }
        ]
    }
}
